// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aviso-de-privacidad-js": () => import("./../../../src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-ayudamos-js": () => import("./../../../src/pages/ayudamos.js" /* webpackChunkName: "component---src-pages-ayudamos-js" */),
  "component---src-pages-colabora-js": () => import("./../../../src/pages/colabora.js" /* webpackChunkName: "component---src-pages-colabora-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/Demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-error-404-js": () => import("./../../../src/pages/Error404.js" /* webpackChunkName: "component---src-pages-error-404-js" */),
  "component---src-pages-hacemos-js": () => import("./../../../src/pages/hacemos.js" /* webpackChunkName: "component---src-pages-hacemos-js" */),
  "component---src-pages-home-one-js": () => import("./../../../src/pages/HomeOne.js" /* webpackChunkName: "component---src-pages-home-one-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/ServiceDetails.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-somos-js": () => import("./../../../src/pages/somos.js" /* webpackChunkName: "component---src-pages-somos-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/Team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-transferencia-js": () => import("./../../../src/pages/transferencia.js" /* webpackChunkName: "component---src-pages-transferencia-js" */)
}

